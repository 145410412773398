.job-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  height: calc(100vh - 56px); /* Adjust height considering navbar */
  overflow: auto;
}

.row {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
}

.col-md-6 {
  padding: 20px;
}

/* Image and quote styling */
.image-quote {
  font-size: 1.5rem; /* Slightly larger for emphasis */
  font-weight: 600; /* Make the quote bold */
  margin-top: 15px;
  color: #ffffff; /* Darker color for better contrast */
}

.img-fluid {
  margin-top: 225px;
}

/* Form container styling */
form {
  margin-top: 50px;
  max-width: 500px; /* Limit the width of the form */
  margin-left: auto; /* Push the form to the right */
  background-color: #ffffff; /* White background for the form */
  padding: 30px; /* Add more padding inside the form */
  border-radius: 10px; /* More rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15); /* Softer shadow for depth */
}

/* Form headings */
h2 {
  margin-bottom: 20px; /* Space between heading and form */
  font-size: 1.8rem; /* Larger heading size */
  color: #333; /* Darker text color */
}

/* Form group styling */
.form-group {
  margin-bottom: 1.5rem; /* Space between form fields */
}

/* Form control styling */
.form-control {
  border: 1px solid #ced4da; /* Light border color */
  border-radius: 5px; /* Slightly rounded corners */
  padding: 5px; /* Increased padding for comfort */
  transition: border-color 0.2s; /* Smooth transition for focus effect */
}

/* Focus effect on form control */
.form-control:focus {
  border-color: #80bdff; /* Change border color on focus */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Bootstrap focus effect */
}

/* Button styling */
.btn {
  background-color: #004bb0; /* Primary button color */
  color: #fff; /* White text color */
  padding: 10px 20px; /* Padding for button */
  border-radius: 5px; /* Rounded corners */
  font-weight: bold; /* Bold text */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

/* Button hover effect */
.btn:hover {
  background-color: #0056b3; /* Darker color on hover */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal is on top */
}

.modal-content {
  background-color: #fff; /* White background */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.modal-content h3 {
  margin-top: 0;
}

.modal-content button {
  margin-top: 10px;
}

/* Job.css */



/* Success Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it overlays everything */
}

.modal-content {
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 80%;
}

.modal-content h3 {
  margin-bottom: 15px;
}

.modal-content p {
  margin-bottom: 20px;
}

.modal-content button {
  padding: 10px 20px;
  font-size: 1rem;
}


