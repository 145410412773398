@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

/* Define the keyframes for the fade-in effect */
@keyframes fadeIn {
  from {
    opacity: 0; /* Start with transparent */
  }
  to {
    opacity: 1; /* End with fully opaque */
  }
}

@keyframes fade {
  0% {
    opacity: 0.5; /* Start slightly faded */
  }
  50% {
    opacity: 1; /* Fully visible */
  }
  100% {
    opacity: 0.5; /* End slightly faded */
  }
}




/* Apply the animation to the header */
.centered-text {
  animation: fadeIn 1s ease-in; /* 1s duration, ease-in timing */
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  height: 100vh;
  overflow: hidden; /* Prevents any unnecessary scroll */
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Homepage-specific styles */
.homepage {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertical centering */
  align-items: center; /* Horizontal centering */
}

.homepage .background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(5px); /* Adjust blur intensity */
  z-index: -1; /* Ensures the video stays in the background */
}

.homepage .centered-text {
  z-index: 1;
  padding: 10px 20px;
  border-radius: 8px;
}

.homepage .centered-text h1 {
  padding-top: 1000px;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  margin: 10px auto 0; /* Reduced top margin, removed bottom margin */
  width: 80%;
  padding-top: 10px; /* Add padding if needed to fine-tune positioning */
}


/* Blank page styles for Find Talent/Find Jobs */
.blank-page {
  background-color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .homepage .centered-text h1 {
    font-size: 20px; /* Adjust the font size for smaller screens */
  }
}
