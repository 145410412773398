/* App.css */

html, body {
    margin: 0;
    height: 100%; /* Ensure the body takes full height */
  }
  
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Set minimum height to fill viewport */
    
  }
  
  .homepage {
    flex: 1; /* This will make the homepage occupy all available space */
    position: relative; /* Position for absolute child elements */
    
  }
  
  .background-video {
    position: absolute; /* Position the video absolutely */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Cover the entire background */
    z-index: -1; /* Send video behind other elements */
    
  }
  
  .centered-text {
    position: relative; /* Relative to keep it above the video */
    z-index: -1; /* Bring text above video */
    color: white; /* Change text color for visibility */
    text-align: center;
    padding: 5px; /* Add some padding */
    
  }
  
  /* Footer styles */
  /* Footer.css */
  footer {
    display: flex;
    grid-template-columns: repeat(2, auto); /* Two columns layout */
    gap: 15px; /* Space between columns */
    background-color: #ffffff; /* Footer background color */
    color: rgb(0, 0, 0); /* Text color */
    padding: 5px; /* Padding */
    margin-top: auto; /* Push footer to bottom */
    height: 80px; /* Fixed height for footer */
    align-items: center; /* Center items vertically */
    place-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
    z-index: 2; /* Higher z-index to be on top */
    position: relative; /* Position to ensure z-index works */
    font-family: 'Open Sans', sans-serif;
    

}

  footer p {
    margin: 0 15px;           /* Space between each text item */
    font-size: 10px;
    white-space: nowrap;      /* Prevents text from wrapping to the next line */
  }
  
  