.header {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: flex-start; /* Align items to the left */
  align-items: center; /* Vertically align items */
  padding: 0px 10px;
  background-color: #ffffff;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1); /* Stronger shadow for more depth */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  gap: 20px; /* Space between sections */
}

.header-left {
  display: flex;
  align-items: center;
}

.logo {
  height: 100px; /* Adjust height as needed */
  cursor: pointer;
  margin-right: 20px; /* Space between logo and buttons */
}

.nav-bar {
  display: flex;
  gap: 20px; /* Space between buttons */
}

.find-talent-btn {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  padding: 7.5px 7.5px;
  background-color: #004bb0;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  
}

.find-jobs-btn {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  padding: 7.5px 7.5px;
  background-color: #004bb0;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  translate: 10px;

}

.find-talent-btn:hover, .find-jobs-btn:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.header-right {
  margin-left: 1250px; /* Pushes header-right section to the far right */
  display: flex;
  flex-direction: column; /* Stack email and phone vertically */
  align-items: flex-end; /* Align to the right */
}

.email {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-right: auto;
  color: #333; /* Set color if needed */

}

.phone {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0px;
  margin-right: auto;
  color: #333; /* Set color if needed */

}

.verbiage {
  height: 125px; /* Adjust height as needed */
  margin-left: -10px; /* Space between logo and buttons */
}

